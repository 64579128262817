<template>
     <!-- PRELOADER -->
    <div id="preloader">
        <div class="loader_line"></div>
    </div>
</template>

<script>
export default {
  name: 'Loader',
  props: [],
}
</script>